// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/images/common/no-image-4by3.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("@/images/common/no-image-16by9.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".embed-responsive-item>*[data-v-09fe167e]{position:absolute;top:50%;left:50%;transform:translate3d(-50%, -50%, 0);max-width:100%;max-height:100%}.embed-responsive-4by3 img.no-image[data-v-09fe167e]{content:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");opacity:.25}.embed-responsive-16by9 img.no-image[data-v-09fe167e]{content:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");opacity:.25}.cover[data-v-09fe167e]{background-color:rgba(0,0,0,.5);width:100%;height:100%}.fail .cover[data-v-09fe167e]{background-color:rgba(255,0,0,.5)}.preview[data-v-09fe167e]{position:absolute;top:50%;left:50%;transform:translate3d(-50%, -50%, 0);max-width:100%;max-height:100%}", "",{"version":3,"sources":["webpack://./app/javascript/src/components/upload-item.vue"],"names":[],"mappings":"AACA,0CACE,iBAAA,CACA,OAAA,CACA,QAAA,CACA,oCAAA,CACA,cAAA,CACA,eAAA,CAGF,qDACE,+CAAA,CACA,WAAA,CAGF,sDACE,+CAAA,CACA,WAAA,CAGF,wBACE,+BAAA,CACA,UAAA,CACA,WAAA,CAGF,8BACE,iCAAA,CAGF,0BACE,iBAAA,CACA,OAAA,CACA,QAAA,CACA,oCAAA,CACA,cAAA,CACA,eAAA","sourcesContent":["\n.embed-responsive-item > * {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate3d(-50%, -50%, 0);\n  max-width: 100%;\n  max-height: 100%;\n}\n\n.embed-responsive-4by3 img.no-image {\n  content: url('@/images/common/no-image-4by3.png');\n  opacity: 0.25;\n}\n\n.embed-responsive-16by9 img.no-image {\n  content: url('@/images/common/no-image-16by9.png');\n  opacity: 0.25;\n}\n\n.cover {\n  background-color: rgba(0, 0, 0, .5);\n  width: 100%;\n  height: 100%;\n}\n\n.fail .cover {\n  background-color: rgba(255, 0, 0, .5);\n}\n\n.preview {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate3d(-50%, -50%, 0);\n  max-width: 100%;\n  max-height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
